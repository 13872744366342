import { useEffect, useState } from "react";
import {
  FaPhoneAlt,
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaPhone,
  FaMailBulk,
} from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Modal, Button, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import globals from "../components/Assets/Choice GLobal icon.png";

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    selectOption: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [userMsg, setUserMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const validate = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.phone) {
      errors.phone = "Phone number is required";
    }

    if (!formData.selectOption) {
      errors.selectOption = "Please select an option";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setLoader(true);
      try {
        const response = await fetch(
          "https://lunarsenterprises.com:5009/choiceglobal/contact-us",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: formData.name,
              email: formData.email,
              phno: formData.phone,
              subject: formData.selectOption,
              message: formData.message,
            }),
          }
        );

        const data = await response.json();

        if (data.result === true) {
          setUserMsg(
            "Email Sent Successfully! Our team will connect with you soon."
          );
          setShowModal(true);
        } else {
          setUserMsg(
            "Email Sent Successfully! Our team will connect with you soon."
          );
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setUserMsg("An error occurred. Please try again later.");
        setShowModal(true);
      } finally {
        setLoader(false);
      }
    } else {
      setErrors(errors);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      selectOption: "",
      message: "",
    });
  };

  //--------------serviceslist

  useEffect(() => {
    ServicesApi();
  }, []);

  const [servicesList, setServicesList] = useState([]);

  const ServicesApi = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5009/choiceglobal/list/services"
      );

      console.log(response.data.list);

      if (response.data.result === true) {
        setServicesList(response.data.list);
      } else {
        alert("Api Fetch issues");
      }
    } catch (error) {}
  };
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const phone = localStorage.getItem("phone");
  const phone1 = localStorage.getItem("phone1");
  return (
    // <div className="container mt-5 mb-5" id="contactus">
    //   <div className="container col-md-12 mt-5 slidescontact">
    //     <div className="row">
    //       <div className="col-md-6">
    //         <h1 className="contactuss">Contact Us</h1>

    //         <div className="contactuss">
    //           {/* <div className="align-items-center d-flex">
    //             <FaPhoneAlt />
    //             <a href="tel:+91 9061268938">
    //               <p className="pt-3 contactusstext contactusstextemail">
    //                 India 9061268938
    //               </p>
    //             </a>
    //           </div> */}

    //           <div className="align-items-center d-flex">
    //             <FaPhoneAlt />

    //             <a href="tel:+96878415929">
    //               <p className="pt-3 contactusstext contactusstextemail">
    //                 +968 78415929
    //               </p>
    //             </a>
    //             <a href="tel:+96878415929">
    //               <p className="pt-3 contactusstext contactusstextemail">
    //                 +968 78849006
    //               </p>
    //             </a>
    //           </div>

    //           <div className="d-flex align-items-center ">
    //             <MdEmail />
    //             <a
    //               className="contactusstext"
    //               href="mailto:choicerecruitmentservices@gmail.com"
    //             >
    //               <p className="pt-3 contactusstextemail">
    //                 choicerecruitmentservices@gmail.com
    //               </p>
    //             </a>
    //           </div>

    //           <div className="d-flex align-items-center d-flex">
    //             <FaLocationDot />
    //             <p className="pt-3 contactusstext contactusstextemail">
    //               CR/1545602 AL-Khuwair,Muscat-133
    //               <br />
    //               Sultanate of Oman
    //             </p>
    //           </div>

    //           <a href="https://wwws-Consultancy/61559892727924/?mibextid=ZbWKwL">
    //             <FaFacebookF className="socialicon" />
    //           </a>
    //           <a
    //             href="https://wa.me/+96878415929"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <FaWhatsapp className="socialicon" />
    //           </a>
    //           <a href="https://x.combclid=IwZXh0bgNhZW0CMTAAAR3Cb81n4P0T7w2RRP0pVOYcEM9lofKAql2YDxELiFItJGwfKpjh8ajgSdA_aem_AY1Yi1jlEyZ3YmqFJpXII_ZrwQGoEpcFK1tQbH6bvc3Gh2aWTGFdav3J7bgbPqPxw9JhuVCN2pK-MQRViZXElq4q">
    //             <FaXTwitter className="socialicon" />
    //           </a>
    //           <a href="httpm/kateexodus/?igsh=W1nZzZvbmNydDBv">
    //             <FaInstagram className="socialicon" />
    //           </a>
    //         </div>
    //       </div>

    //       <form onSubmit={handleSubmit} className="col-md-6">
    //         <div className="mb-2">
    //           <label>Full Name</label>
    //         </div>
    //         <input
    //           className="inputstyle"
    //           placeholder=""
    //           type="text"
    //           name="name"
    //           value={formData.name}
    //           onChange={handleChange}
    //         />
    //         {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}

    //         <div className="mt-2">
    //           <label>Email</label>
    //         </div>
    //         <input
    //           className="inputstyle"
    //           type="email"
    //           name="email"
    //           value={formData.email}
    //           onChange={handleChange}
    //         />
    //         {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

    //         <div className="mt-2 mb-2">
    //           <label>Contact Number</label>
    //         </div>
    //         <PhoneInput
    //           country={"om"}
    //           value={formData.phone}
    //           onChange={handlePhoneChange}
    //           inputStyle={{ height: "30px" }}
    //           inputClass="form-control inputstyle"
    //           required
    //         />
    //         {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}

    //         <div className="mt-3">
    //           <label>Subject</label>
    //         </div>
    //         <select
    //           className="inputstyle"
    //           name="selectOption"
    //           value={formData.selectOption}
    //           onChange={handleChange}
    //         >
    //           <option value="">Services</option>

    //           {servicesList.map((course) => (
    //             <option key={course.cs_id} value={course.s_name}>
    //               {course.s_name}
    //             </option>
    //           ))}
    //           {/* <option value="EDUCATION AND RECRUITMENT SERVICES">EDUCATION AND RECRUITMENT SERVICES</option>
    //           <option value="IATA TRAINING">IATA TRAINING</option>
    //           <option value="AIRPORT MANAGEMENT TRAINING">AIRPORT MANAGEMENT TRAINING</option>
    //           <option value="RECRUITMENT SERVICES">RECRUITMENT SERVICES</option>
    //           <option value="MEDICAL TOURISM">MEDICAL TOURISM</option>
    //           <option value="TICKETING & VISA PROCESSING">TICKETING & VISA PROCESSING</option>
    //           <option value="TRAINING PROGRAMS">TRAINING PROGRAMS</option>
    //           <option value="SKILL DEVELOPMENT WORKSHOPS">SKILL DEVELOPMENT WORKSHOPS</option>
    //           <option value="STUDY ABROAD PROGRAMS">STUDY ABROAD PROGRAMS</option>
    //           <option value="COLLEGE ADMISSION ASSISTANCE">COLLEGE ADMISSION ASSISTANCE</option>
    //           <option value="TEST PREPARATION (IELTS)">TEST PREPARATION (IELTS)</option>
    //           <option value="CAREER COUNSELING">CAREER COUNSELING</option> */}
    //         </select>
    //         {errors.selectOption && (
    //           <p style={{ color: "red" }}>{errors.selectOption}</p>
    //         )}

    //         <div className="mt-2">
    //           <label>Message</label>
    //         </div>
    //         <textarea
    //           className="inputstylearea"
    //           placeholder=""
    //           name="message"
    //           value={formData.message}
    //           onChange={handleChange}
    //         />
    //         {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}

    //         <button className="submitbutton" type="submit">
    //           {loader ? <Spinner animation="border" size="sm" /> : "Submit"}
    //         </button>
    //       </form>

    //       <Modal show={showModal} onHide={handleCloseModal}>
    //         <Modal.Header closeButton>
    //           <Modal.Title>Email Sent</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //           <p>{userMsg}</p>
    //         </Modal.Body>
    //         <Modal.Footer>
    //           <Button variant="secondary" onClick={handleCloseModal}>
    //             Close
    //           </Button>
    //         </Modal.Footer>
    //       </Modal>
    //     </div>
    //   </div>
    // </div>
    <div
      className="container-fluid px-2 px-md-4 py-5 mx-auto"
      style={{ maxWidth: "1020px" }}
    >
      <div className="card card0 border-0">
        <div className="row d-flex">
          <div className="col-lg-5">
            <div className="card1 pb-5">
              <div className="row px-3">
                <h3 className="logo d-flex justify-content-center">
                  Contact Us
                </h3>
              </div>
              <div className="row px-3 justify-content-center mt-4 mb-5">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active card border-0 card-0">
                      <div className="text-center">
                        <img src={globals} className="img-fluid profile-pic" />
                      </div>
                      <h6 className="font-weight-bold mt-5 d-flex justify-content-center">
                        Choice Global
                      </h6>{" "}
                      <div className="d-flex align-items-center ">
                        <FaPhone />
                        <p className="contentnew mt-2 mb-0 d-flex justify-content-center contactusstext contactusstextemail">
                          +{phone}
                        </p>
                      </div>
                      <div className="d-flex align-items-center ">
                        <FaPhone />
                        <p className="contentnew mt-2 mb-0 d-flex justify-content-center contactusstext contactusstextemail">
                          +{phone1}
                        </p>
                      </div>
                      <div className="d-flex align-items-center ">
                        <FaLocationDot />
                        <p className="pt-3 contactusstext contactusstextemail">
                          {address}
                        </p>
                      </div>
                      <div className="d-flex align-items-center d-flex justify-content-center">
                        <span className="fa fa-instagram mx-2">
                          <a href={`mailto:${email}`}>
                            <FaMailBulk className="socialicon" />
                          </a>
                        </span>
                        <span className="fa fa-facebook-square mx-2">
                          <a href="https://wwws-Consultancy/61559892727924/?mibextid=ZbWKwL">
                            <FaFacebookF className="socialicon" />
                          </a>
                        </span>
                        <span className="fa fa-youtube-play mx-2">
                          <a
                            href="https://wa.me/+96878415929"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaWhatsapp className="socialicon" />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="card2 card border-0 px-4 px-sm-5 py-5">
              <h3 className="mb-1">Get Started</h3>
              <p className="mb-4 text-sm">
                Let us help you find the workforce that drives your success
              </p>

              <div className="row mt-3">
                <div className="col-md-6">
                  <label className="mb-0">
                    <h6 className="mb-0 text-sm">Full Name</h6>
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                </div>
                <div className="col-md-6">
                  <label className="mb-0">
                    <h6 className="mb-0 text-sm">Contact Number</h6>
                  </label>
                  <PhoneInput
                    country={"om"}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputStyle={{
                      height: "45px",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    inputClass="phoneinput"
                    required
                  />
                  {errors.phone && (
                    <p style={{ color: "red" }}>{errors.phone}</p>
                  )}
                </div>
              </div>
              <div className="row px-3 email-section">
                <label className="mb-0">
                  <h6
                    className=" text-sm email-label"
                    style={{ marginLeft: "-15px" }}
                  >
                    Email Address
                  </h6>
                </label>

                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              </div>
              <div className="row px-3">
                <label className="mb-0">
                  <h6 className="mb-0 text-sm" style={{ marginLeft: "-15px" }}>
                    Subject
                  </h6>
                </label>
                <select
                  className="phoneinput"
                  name="selectOption"
                  value={formData.selectOption}
                  onChange={handleChange}
                >
                  <option value="">Services</option>

                  {servicesList.map((course) => (
                    <option key={course.cs_id} value={course.s_name}>
                      {course.s_name}
                    </option>
                  ))}
                </select>
                {errors.selectOption && (
                  <p style={{ color: "red" }}>{errors.selectOption}</p>
                )}
              </div>
              <div className="col-md-6">
                <label className="mb-0">
                  <h6 className="mb-0 text-sm">Message</h6>
                </label>
                <textarea
                  type="text"
                  name="message"
                  placeholder="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && (
                  <p style={{ color: "red" }}>{errors.message}</p>
                )}
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <button
                    className="btn btn-blue text-center mb-1 py-2"
                    onClick={handleSubmit}
                  >
                    {loader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Email Sent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{userMsg}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
           
      </div>
    </div>
  );
};

export default EnquiryForm;
