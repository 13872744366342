import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const Navigate = useNavigate();
  const handleNavigate = (serviceTitle) => {
    Navigate(`/service/${encodeURIComponent(serviceTitle)}`);
  };
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  const phone = localStorage.getItem("phone");
  const phone1 = localStorage.getItem("phone1");
  return (
    <>
      <div className="footer footer-bg ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 align-items-center justify-content-center d-flex">
              <img src="/Assets/Logo.png" width="250" height="55" alt="logo" />
            </div>
            <div className="col-md-4">
              <h1 className="servfooter mt-4 mb-3">OUR SERVICES</h1>

              <p
                className="hovereffect"
                onClick={() => handleNavigate("Permanent Staffing")}
              >
                Permanent Staffing
              </p>
              <p
                className="hovereffect"
                onClick={() => handleNavigate("Temporary Staffing")}
              >
                Temporary Staffing
              </p>
              <p
                className="hovereffect"
                onClick={() => handleNavigate("Event Staffing")}
              >
                Event Staffing
              </p>
              <p
                className="hovereffect"
                onClick={() => handleNavigate("Business Staffing")}
              >
                Business Staffing
              </p>
            </div>
            <div className="col-md-4">
              <h1 className="servfooter mt-4 mb-3">CONTACT INFO</h1>
              <a href={`tel:${phone}`}>
                <p className="text-black">
                  {" "}
                  <IoCall color="black" /> +{phone}
                </p>
              </a>
              <a href={`tel:${phone1}`}>
                <p className="text-black">
                  {" "}
                  <IoCall color="black" /> +{phone1}
                </p>
              </a>
              <div className="d-flex align-items-center">
                <MdEmail style={{ marginBottom: "10px" }} />
                <p className="text-black">
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
              <div className="text-black d-flex">
                {" "}
                <FaLocationDot color="black" />
                <p className="text-black">{address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div className="copy-right-text">© copyrights 2024</div>
      </div>
    </>
  );
}
